import { Box, BoxProps, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { CoursesCategoriesTabsList } from '../model/courses-categories-tabs-const';
import TabCourseCategory from './components/TabCourseCategory';
import { CardCourseConditionalSpacedDark } from '@/entities/cards/card-course';

export default function CoursesCategoriesTabs({ styles }: { styles?: BoxProps }) {
    const [tabIndex, setTabIndex] = useState(0);

    const onTabsChange = (index: any) => {
        setTabIndex(index);
    };

    return (
        <Tabs
            onChange={onTabsChange}
            isLazy
            defaultIndex={0}
            variant="dark"
            colorScheme="teal"
            {...styles}
        >
            <Text
                maxW="500px"
                mx="auto"
                mb={{ base: 4, md: 6 }}
                textAlign="center"
                color="white"
                fontSize={{ base: '24px', md: '32px' }}
                fontWeight={600}
            >
                A Spark of Inspiration Anytime You Need It
            </Text>

            <TabList flexWrap="wrap">
                {CoursesCategoriesTabsList.map(({ icon, title, id }) => (
                    <Tab key={id}>
                        <Box as={icon} />
                        {title}
                    </Tab>
                ))}
            </TabList>

            <TabPanels minH="300px">
                {CoursesCategoriesTabsList.map(({ subTitle, id }) => (
                    <TabPanel
                        key={id}
                        px={{ base: 5, md: 0 }}
                        mx={{ base: -5, md: 0 }}
                    >
                        <TabCourseCategory
                            id={id}
                            subTitle={subTitle}
                            CardEl={CardCourseConditionalSpacedDark}
                        />
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
}
