const COURSES_COLLECTIONS = [
    {
        id: 1,
        title: 'Выбор редакции',
    },
    {
        id: 2,
        title: 'Популярное на Edston',
    },
    {
        id: 3,
        title: 'Йога для женщин',
    },
    {
        id: 4,
        title: 'Осанка',
    },
    {
        id: 5,
        title: 'Йога ',
    },
    {
        id: 6,
        title: 'Здоровая спина',
    },
    {
        id: 7,
        title: 'Цигун',
    },
    {
        id: 8,
        title: 'Шпагат',
    },
    {
        id: 9,
        title: 'Вокал',
    },
    {
        id: 10,
        title: 'Танцы',
    },
    {
        id: 12,
        title: 'Танцы для похудения',
    },
    {
        id: 14,
        title: 'Мама и ребенок',
    },
    {
        id: 15,
        title: 'Бизнес',
    },
    {
        id: 16,
        title: 'Фитнес и онлайн-тренировки',
    },
    {
        id: 17,
        title: 'Растяжка',
    },
    {
        id: 18,
        title: 'Массаж',
    },
    {
        id: 19,
        title: 'Feminine Energy & Embodiment',
    },
    {
        id: 20,
        title: 'Singing & Healing',
    },
    {
        id: 21,
        title: 'Relationships & Intimacy',
    },
    {
        id: 22,
        title: 'Mindfulness',
    },
    {
        id: 23,
        title: 'Brain',
    },
    {
        id: 24,
        title: 'Massage',
    },
    {
        id: 25,
        title: 'Fitness & Health',
    },
    {
        id: 26,
        title: 'Course of the week',
    },
    {
        id: 27,
        title: 'Sale',
    },
    {
        id: 28,
        title: 'Creativity & Arts',
    },
    {
        id: 29,
        title: 'Dance & Movement',
    },
    {
        id: 30,
        title: 'Personal Growth',
    },
    {
        id: 31,
        title: 'Pregnancy & Parenting',
    },
];

export enum CoursesCollections {
    Popular = 2,
    FeminineEnergyEmbodiment = 19,
    Singing = 20,
    Relationships = 21,
    Mindfulness = 22,
    Brain = 23,
    Massage = 24,
    Fitness = 25,
    Bestseller = 26,
    Sale = 27,
    CreativityArts = 28,
    DanceMovement = 29,
    PersonalGrowth = 30,
    PregnancyParenting = 31,
}
