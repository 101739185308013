import { Img, Progress, Box, Text, VStack, HStack, Flex, Link } from '@chakra-ui/react';
import { RiPlayCircleFill } from 'react-icons/ri';

import { CardCourseConditionalProps } from '../model/types';
import { colors } from '@/common/theme/constant';
import CsrPaywallWrapper from '@/entities/csr/csr-paywall-wrapper/CsrPaywallWrapper';
import { csrStore, paywallCourseName, paywallOpenStateAtom } from '@/entities/csr/store';

// jump to course в случае, если куплена подписка, либо курс.
// Подробнее и смотреть бесплатно, если подписки нет, либо курс не куплен.
// Если не залогинен, то цена

export default function CardCourseConditionalSpacedDark({
    course,
    isGuest,
    isContinueWatch,
    isSubscription,
    styles,
    actionSubType,
}: CardCourseConditionalProps) {
    const { imgSrc, title, authorNames, price, priceDiscount, currency, slug, isBought, progress } = course;

    const renderPrice = () => {
        if (priceDiscount) {
            return (
                <HStack
                    as="span"
                    gap={1}
                    fontSize={{ base: '20px', md: '24px' }}
                    fontWeight={600}
                    lineHeight={1.3}
                >
                    <Text
                        as="span"
                        color="gray.500"
                        textDecoration="line-through"
                    >
                        {currency}
                        {priceDiscount}
                    </Text>
                    <Text as="span">
                        {currency}
                        {price}
                    </Text>
                </HStack>
            );
        }

        if (!priceDiscount) {
            return (
                <Flex
                    as="span"
                    gap="4px"
                    fontSize={{ base: '20px', md: '24px' }}
                    fontWeight={600}
                    lineHeight={1.3}
                >
                    <Text mb={0}>
                        {currency} {price}
                    </Text>
                </Flex>
            );
        }
    };

    const renderContinueWatch = () => {
        if (isContinueWatch) {
            return (
                <Link
                    href={`/client/watch/${slug}`}
                    variant={'stretched'}
                >
                    <Text
                        as="span"
                        fontSize={{ base: '20px', md: '24px' }}
                        fontWeight={600}
                        lineHeight={1.3}
                    >
                        Proceed to watch
                    </Text>
                </Link>
            );
        }

        if (!isContinueWatch) {
            return (
                <Link
                    href={`/${slug}`}
                    variant={'stretched'}
                >
                    <Text
                        as="span"
                        fontSize={{ base: '20px', md: '24px' }}
                        fontWeight={600}
                        lineHeight={1.3}
                    >
                        Jump to course
                    </Text>
                </Link>
            );
        }
    };

    const renderAuthBottomBlock = () => {
        return isBought || isSubscription ? (
            renderContinueWatch()
        ) : (
            <>
                <Link
                    href={`/${slug}`}
                    variant="default"
                >
                    <Text mb={0}>Learn more</Text>
                </Link>

                <HStack
                    justifyContent="space-between"
                    cursor="pointer"
                    _hover={{ color: 'green.400', opacity: '0.7' }}
                    onClick={() => {
                        csrStore.set(paywallCourseName, title);
                        csrStore.set(paywallOpenStateAtom, true);
                    }}
                >
                    <Text as="span">Start for free</Text>

                    <RiPlayCircleFill
                        color={colors.green[400]}
                        fontSize="19px"
                    />
                </HStack>
                <CsrPaywallWrapper actionSubType={actionSubType} />
            </>
        );
    };

    return (
        <Box
            pos="relative"
            px={{ base: '20px', md: '28px' }}
            py={{ base: '20px', md: '20px' }}
            borderRadius="16px"
            bgColor="#252525"
            overflow="hidden"
            transition={'box-shadow 0.25s ease-in-out'}
            _hover={{
                boxShadow: '-4px -4px 25px 0 rgba(255, 255, 255, 0.15), 4px 4px 25px 0 rgba(255, 255, 255, 0.15)',
            }}
            {...styles}
        >
            <Img
                src={imgSrc || ''}
                alt="course-img"
                h={{ base: '150px', md: '180px' }}
                w={{ base: '260px', md: '100%' }}
                objectFit="cover"
                borderRadius="8px"
            />

            {!!progress && (
                <Progress
                    value={progress}
                    variant="card"
                />
            )}

            <Box
                p={5}
                color="white"
            >
                <VStack
                    align="flex-start"
                    mb={2}
                >
                    <Text
                        mb={0}
                        fontSize={{ base: '16px', md: '18px' }}
                        noOfLines={2}
                        h="50px"
                    >
                        {title}
                    </Text>

                    {Array.isArray(authorNames) && (
                        <Text
                            textStyle="truncate-line"
                            mb={0}
                            fontSize="sm"
                            color="#c1c1c1"
                            w="100%"
                        >
                            by{' '}
                            {authorNames.map(({ name }, index) => (
                                <Text
                                    key={name}
                                    as="span"
                                >
                                    {name}
                                    {index !== authorNames.length - 1 ? ' • ' : ''}
                                </Text>
                            ))}
                        </Text>
                    )}
                </VStack>

                {isGuest ? (
                    <Link
                        href={`/${slug}`}
                        variant="stretched"
                    >
                        {renderPrice()}
                    </Link>
                ) : (
                    renderAuthBottomBlock()
                )}
            </Box>
        </Box>
    );
}
