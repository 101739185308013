import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Box, Button, Center, Flex, Spinner, Text, useMediaQuery } from '@chakra-ui/react';

import { formattedCourseList } from '@/features/courses/courses-list/utils/formattedCourseList';
import { ActionSubType } from '@/features/subscription/create-sub';
import { userAtom } from '@/shared/store';
import { DefaultSlider } from '@/entities/lists/sliders/primary-slider';
import { useCoursesListByValue, SearchType } from '@/features/courses/courses-list';

export default function TabCourseCategory({ subTitle, id, CardEl }: any) {
    const [coursesListLocal, setCoursesListLocal] = useState<any | null>(null);
    const [visibleCourses, setVisibleCourses] = useState<number>(6);

    const user = useAtomValue(userAtom);
    const [isMoreThanMd] = useMediaQuery(`(min-width: 768px)`, { ssr: false });
    const { coursesList, isCoursesLoading } = useCoursesListByValue(SearchType.Collection, [id]);

    useEffect(() => {
        if (coursesList) {
            setCoursesListLocal(formattedCourseList(coursesList, user, ActionSubType.AfterRecQuizBannerDashboard));
        }
    }, [coursesList]);

    if (isCoursesLoading) {
        return (
            <Center h="290px">
                <Spinner />
            </Center>
        );
    }

    const onShowMoreBtn = () => {
        if (visibleCourses < coursesListLocal.length) {
            setVisibleCourses(coursesListLocal.length);
        } else {
            setVisibleCourses(6);
        }
    };

    return (
        <Box>
            {coursesListLocal && subTitle && (
                <Text
                    mb={4}
                    fontSize={{ base: '18px', md: '20px' }}
                    fontWeight={500}
                    color="white"
                >
                    {subTitle}
                </Text>
            )}

            {coursesListLocal ? (
                <>
                    {isMoreThanMd ? (
                        <Flex
                            flexWrap="wrap"
                            gap={4}
                        >
                            {coursesListLocal.slice(0, visibleCourses).map((item: any) => (
                                <Box
                                    key={`selected-course-${item.course.title}`}
                                    w="calc(33% - 8px)"
                                >
                                    <CardEl
                                        course={item.course}
                                        isGuest={item.isGuest}
                                        isSubscription={item.isSubscription}
                                        actionSubType={item.actionSubType}
                                    />
                                </Box>
                            ))}
                        </Flex>
                    ) : (
                        <DefaultSlider
                            listData={coursesListLocal.slice(0, visibleCourses)}
                            CardEl={(item: any) => (
                                <CardEl
                                    {...item}
                                    styles={{ width: '300px' }}
                                />
                            )}
                        />
                    )}
                    <Center mt={6}>
                        <Button
                            onClick={onShowMoreBtn}
                            variant="white-blue"
                        >
                            {visibleCourses < coursesListLocal.length ? 'Load more' : 'Show less courses'}
                        </Button>
                    </Center>
                </>
            ) : (
                <div>Error</div>
            )}
        </Box>
    );
}
