'use client';

import { ReactNode, useId, useState } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { CardCourseConditional, CardCourseProps } from '@/entities/cards/card-course';

import 'swiper/css';
import 'swiper/css/pagination';
import { NavBtn, NavBtnType } from '@/entities/btns/nav-btn';

const LIMIT_SLIDES = 5;

interface DefaultSliderProps {
    listData: CardCourseProps[] | any;
    styles?: BoxProps;
    navLeftStyles?: BoxProps;
    navRightStyles?: BoxProps;
    limitSlides?: number;
    CardEl?: (item: any) => ReactNode;
    sliderProps?: SwiperProps;
    isNavShow?: boolean;
}

export default function DefaultSlider({
    listData,
    limitSlides = LIMIT_SLIDES,
    styles,
    CardEl = (item: any) => (
        <CardCourseConditional
            {...item}
            styles={{ w: '220px' }}
        />
    ),
    sliderProps,
    isNavShow,
    navLeftStyles,
    navRightStyles,
}: DefaultSliderProps) {
    const [swiperState, setSwiperState] = useState<SwiperClass>();
    const id = useId();

    const handleNext = () => {
        swiperState?.slideNext();
    };
    const handlePrevious = () => {
        swiperState?.slidePrev();
    };

    return (
        <Box
            position="relative"
            {...styles}
            sx={{
                '& .swiper': {
                    m: -5,
                    p: 5,
                },

                '& .swiper-pagination': {
                    position: 'relative',
                },

                '& .swiper-slide.swiper-slide-prev > div': {
                    opacity: 0,
                },
                '& .swiper-slide > div': {
                    opacity: 0,
                },
                '& .swiper-slide.swiper-slide-visible > div': {
                    opacity: 1,
                    transition: '0.23s ease-in-out',
                },
            }}
        >
            <Swiper
                slidesPerView="auto"
                spaceBetween="15"
                modules={[Pagination]}
                watchSlidesProgress={true}
                onSwiper={(swiper: SwiperClass) => setSwiperState(swiper)}
                {...sliderProps}
            >
                {listData.map((item: CardCourseProps, index: number) => {
                    return (
                        <SwiperSlide
                            key={`${index}-${id}`}
                            style={{
                                width: 'auto',
                                height: 'auto',
                            }}
                        >
                            <CardEl
                                {...item}
                                styles={{
                                    maxH: '430px',
                                }}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <Box
                hideBelow={isNavShow ? '' : 'md'}
                display={listData.length > limitSlides || isNavShow ? '' : 'none'}
            >
                <NavBtn
                    onClick={handlePrevious}
                    type={NavBtnType.Previous}
                    styles={{
                        position: 'absolute',
                        top: '58px',
                        left: '-20px',
                        ...navLeftStyles,
                    }}
                />

                <NavBtn
                    onClick={handleNext}
                    type={NavBtnType.Next}
                    styles={{
                        position: 'absolute',
                        top: '58px',
                        right: '-20px',
                        ...navRightStyles,
                    }}
                />
            </Box>
        </Box>
    );
}
