import { Link, HStack } from '@chakra-ui/react';
import { RiArrowRightLine, RiPlayCircleFill } from 'react-icons/ri';

import { colors } from '@/common/theme/constant';

interface CourseLinkProps {
    href: string;
    children: React.ReactNode;
    isGreenPlayIcon?: boolean;
    variant?: string;
}

export default function CourseLink({ href, children, isGreenPlayIcon, variant = 'stretched' }: CourseLinkProps) {
    return (
        <HStack
            as={Link}
            justifyContent="space-between"
            href={href}
            variant={variant}
        >
            {children}

            {isGreenPlayIcon ? (
                <RiPlayCircleFill
                    color={colors.green[400]}
                    fontSize="19px"
                />
            ) : (
                <RiArrowRightLine fontSize="19px" />
            )}
        </HStack>
    );
}
