import { RiStarFill } from 'react-icons/ri';
import EarthIcon from 'mdi-react/EarthIcon';
import DanceBallroomIcon from 'mdi-react/DanceBallroomIcon';
import DumbbellIcon from 'mdi-react/DumbbellIcon';
import MovieIcon from 'mdi-react/MovieIcon';
import HeadSnowflakeIcon from 'mdi-react/HeadSnowflakeIcon';
import BottleTonicPlusIcon from 'mdi-react/BottleTonicPlusIcon';
import FaceWomanIcon from 'mdi-react/FaceWomanIcon';
import BedKingIcon from 'mdi-react/BedKingIcon';
import PercentCircleIcon from 'mdi-react/PercentCircleIcon';
import TimerSandFullIcon from 'mdi-react/TimerSandFullIcon';
import HumanMaleFemaleIcon from 'mdi-react/HumanMaleFemaleIcon';
import FinanceIcon from 'mdi-react/FinanceIcon';
import BabyCarriageIcon from 'mdi-react/BabyCarriageIcon';
import { CoursesCollections } from '@/features/courses/courses-list/model/courses-list-const';

export const CoursesCategoriesTabsList = [
    {
        icon: RiStarFill,
        title: 'Most popular',
        subTitle: 'Popular right now',
        id: CoursesCollections.Bestseller,
    },
    // {
    //     icon: EarthIcon,
    //     title: 'All courses',
    //     subTitle: 'Explore all offerings',
    //     id: 0,
    // },
    {
        icon: DanceBallroomIcon,
        title: 'Dance & movement',
        subTitle: 'Move your body, free your mind',
        id: CoursesCollections.DanceMovement,
    },
    {
        icon: DumbbellIcon,
        title: 'Fitness & Health',
        subTitle: 'Strong body, healthy life',
        id: CoursesCollections.Fitness,
    },
    {
        icon: MovieIcon,
        title: 'Creativity & Arts',
        subTitle: 'Unleash your inner artist',
        id: CoursesCollections.CreativityArts,
    },
    {
        icon: HeadSnowflakeIcon,
        title: 'Brain',
        subTitle: 'Train your mind, expand your potential',
        id: CoursesCollections.Brain,
    },
    {
        icon: BottleTonicPlusIcon,
        title: 'Singing & Healing',
        subTitle: 'Healing through sound and voice',
        id: CoursesCollections.Singing,
    },
    {
        icon: FaceWomanIcon,
        title: 'Feminine Energy & Embodiment',
        subTitle: 'Connect to your feminine essence',
        id: CoursesCollections.FeminineEnergyEmbodiment,
    },
    {
        icon: BedKingIcon,
        title: 'Massage',
        subTitle: 'Hands-on relaxation and therapy',
        id: CoursesCollections.Massage,
    },
    {
        icon: PercentCircleIcon,
        title: 'Sale',
        subTitle: 'Best deals, limited time',
        id: CoursesCollections.Sale,
    },
    {
        icon: TimerSandFullIcon,
        title: 'Mindfulness',
        subTitle: 'Find peace in the present moment',
        id: CoursesCollections.Mindfulness,
    },
    {
        icon: HumanMaleFemaleIcon,
        title: 'Relationships & Intimacy',
        subTitle: 'Deeper connections, meaningful bonds',
        id: CoursesCollections.Relationships,
    },
    {
        icon: FinanceIcon,
        title: 'Personal Growth',
        subTitle: 'Be the best version of yourself',
        id: CoursesCollections.PersonalGrowth,
    },
    {
        icon: BabyCarriageIcon,
        title: 'Pregnancy & Parenting',
        subTitle: 'Guidance for every step of the journey',
        id: CoursesCollections.PregnancyParenting,
    },
];
