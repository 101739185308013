import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import { CoursesCategoriesTabs, CoursesCategoriesTabsBlue } from '@/features/tabs/courses-categories';
import { ActivityTrackerType, useActivityTracker } from '@/shared/lib';

// Трекер активности
const activityTrackerNode = document.getElementById('activityTracker');
if (activityTrackerNode) {
    const root = createRoot(activityTrackerNode);

    const ActivityTrackerComponent = () => {
        useActivityTracker({
            activityType: ActivityTrackerType.ACTIVITY_HOME_PAGE_VIEW,
        });

        return null;
    };

    root.render(<ActivityTrackerComponent />);
}

// Категории курсов
const coursesCategoriesTabsNode = document.getElementById('coursesCategoriesTabs');
if (coursesCategoriesTabsNode) {
    const root = createRoot(coursesCategoriesTabsNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <CoursesCategoriesTabs />
        </ProvidersCsr>,
    );
}

// Категории курсов для zenovaspace
const coursesCategoriesZnvTabsNode = document.getElementById('coursesCategoriesTabsZnv');
if (coursesCategoriesZnvTabsNode) {
    const root = createRoot(coursesCategoriesZnvTabsNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <CoursesCategoriesTabsBlue />
        </ProvidersCsr>,
    );
}
