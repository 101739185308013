import { BoxProps, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { CoursesCategoriesTabsList } from '../model/courses-categories-tabs-const';
import TabCourseCategory from './components/TabCourseCategory';
import { CardCourseConditionalBlue } from '@/entities/cards/card-course';

export default function CoursesCategoriesTabsBlue({ styles }: { styles?: BoxProps }) {
    const [tabIndex, setTabIndex] = useState(0);

    const onTabsChange = (index: any) => {
        setTabIndex(index);
    };

    return (
        <Tabs
            onChange={onTabsChange}
            isLazy
            defaultIndex={0}
            variant="blue"
            colorScheme="teal"
            {...styles}
        >
            <Text
                maxW="780px"
                mb={{ base: 6, md: 7 }}
                color="#525dd0"
                fontSize={{ base: '24px', md: '32px' }}
                fontWeight={800}
                letterSpacing={'-0.01em'}
                lineHeight={1.2}
            >
                A selection of courses that will help you uncover your hidden skills
            </Text>

            <TabList flexWrap="wrap">
                {CoursesCategoriesTabsList.map(({ title, id }) => (
                    <Tab key={id}>{title}</Tab>
                ))}
            </TabList>

            <TabPanels minH="300px">
                {CoursesCategoriesTabsList.map(({ id }) => (
                    <TabPanel
                        key={id}
                        px={{ base: 5, md: 0 }}
                        mx={{ base: -5, md: 0 }}
                    >
                        <TabCourseCategory
                            id={id}
                            CardEl={CardCourseConditionalBlue}
                        />
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
}
