import { Center, Image } from '@chakra-ui/react';

import { NavBtnType } from '../nav-btn-const';

export default function NavBtn({ onClick, type, styles }: any) {
    return (
        <Center
            className="image-swiper-button"
            onClick={onClick}
            cursor="pointer"
            zIndex={20}
            w="48px"
            h="48px"
            borderRadius="50%"
            bg="white"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            color={'green.400'}
            {...styles}
        >
            <Image
                src={
                    type === NavBtnType.Next ? '/img/icons/arrow-right-s-line.svg' : '/img/icons/arrow-left-s-line.svg'
                }
                alt="arrow"
                w="24px"
            />
        </Center>
    );
}
