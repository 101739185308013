import { Img, Progress, Divider, Box, Text, VStack, HStack, Flex } from '@chakra-ui/react';
import { RiPlayCircleFill } from 'react-icons/ri';

import { CardCourseConditionalProps } from '../model/types';
import CourseLink from './component/CourseLink';
import { colors } from '@/common/theme/constant';
import CsrPaywallWrapper from '@/entities/csr/csr-paywall-wrapper/CsrPaywallWrapper';
import { csrStore, paywallCourseName, paywallOpenStateAtom } from '@/entities/csr/store';

// jump to course в случае, если куплена подписка, либо курс.
// Подробнее и смотреть бесплатно, если подписки нет, либо курс не куплен.
// Если не залогинен, то цена

export default function CardCourseConditional({
    course,
    isGuest,
    isContinueWatch,
    isSubscription,
    styles,
    actionSubType,
}: CardCourseConditionalProps) {
    const { imgSrc, title, authorNames, price, priceDiscount, currency, slug, isBought, progress } = course;

    const renderPrice = () => {
        if (priceDiscount) {
            return (
                <HStack
                    fontSize="sm3"
                    gap={1}
                >
                    <Text
                        as="span"
                        color="gray.500"
                        textDecoration="line-through"
                    >
                        {currency} {priceDiscount}
                    </Text>
                    <Text as="span">
                        {currency} {price}
                    </Text>
                </HStack>
            );
        }

        if (!priceDiscount) {
            return (
                <Flex
                    gap="4px"
                    fontSize="15px"
                >
                    <Text mb={0}>
                        {currency} {price}
                    </Text>
                </Flex>
            );
        }
    };

    const renderContinueWatch = () => {
        if (isContinueWatch) {
            return (
                <CourseLink
                    href={`/client/watch/${slug}`}
                    isGreenPlayIcon={true}
                >
                    <Text mb={0}>Proceed to watch</Text>
                </CourseLink>
            );
        }

        if (!isContinueWatch) {
            return (
                <CourseLink
                    href={`/${slug}`}
                    isGreenPlayIcon={true}
                >
                    <Text mb={0}>Jump to course</Text>
                </CourseLink>
            );
        }
    };

    const renderAuthBottomBlock = () => {
        return isBought || isSubscription ? (
            renderContinueWatch()
        ) : (
            <>
                <CourseLink
                    href={`/${slug}`}
                    isGreenPlayIcon={false}
                    variant="default"
                >
                    <Text mb={0}>Learn more</Text>
                </CourseLink>

                <Divider
                    mt={5}
                    mb={4}
                    color="gray.500"
                    opacity={0.25}
                />

                <HStack
                    justifyContent="space-between"
                    cursor="pointer"
                    _hover={{ color: 'green.400', opacity: '0.7' }}
                    onClick={() => {
                        csrStore.set(paywallCourseName, title);
                        csrStore.set(paywallOpenStateAtom, true);
                    }}
                >
                    <Text as="span">Start for free</Text>

                    <RiPlayCircleFill
                        color={colors.green[400]}
                        fontSize="19px"
                    />
                </HStack>
                <CsrPaywallWrapper actionSubType={actionSubType} />
            </>
        );
    };

    return (
        <Box
            pos="relative"
            borderRadius="xl"
            boxShadow="0 4px 20px rgb(128 128 128 / 15%)"
            overflow="hidden"
            {...styles}
        >
            <Img
                src={imgSrc || ''}
                alt="course-img"
                h="115px"
                w="100%"
                objectFit="cover"
            />

            {!!progress && (
                <Progress
                    value={progress}
                    variant="card"
                />
            )}

            <Box p={5}>
                <VStack align="flex-start">
                    <Text
                        mb={2.5}
                        fontSize="sm3"
                        noOfLines={2}
                        h="44px"
                    >
                        {title}
                    </Text>

                    <Text
                        textStyle="truncate-line"
                        mb={0}
                        fontSize="sm"
                        color="gray.500"
                        w="100%"
                    >
                        {authorNames.map(({ name }, index) => (
                            <Text
                                key={name}
                                as="span"
                            >
                                {name}
                                {index !== authorNames.length - 1 ? ' • ' : ''}
                            </Text>
                        ))}
                    </Text>
                </VStack>

                <Divider
                    color="gray.500"
                    opacity={0.25}
                    my={4}
                />

                {isGuest ? (
                    <CourseLink
                        href={`/${slug}`}
                        isGreenPlayIcon={false}
                    >
                        {renderPrice()}
                    </CourseLink>
                ) : (
                    renderAuthBottomBlock()
                )}
            </Box>
        </Box>
    );
}
