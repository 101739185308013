import { useEffect, useRef, useState, useCallback } from 'react';

import fetchData from '../api/fetch-data';
import { URLS } from '@/common/constants/global';
import { METHODS } from '../api/types';

export enum ActivityTrackerType {
    ACTIVITY_COURSE_VIEW = 'course_view',
    ACTIVITY_DASHBOARD_BROWSE = 'dashboard_browse',
    ACTIVITY_STORY_VIEW = 'story_view',
    ACTIVITY_CHALLENGE_VIEW = 'challenge_view',
    ACTIVITY_HOME_PAGE_VIEW = 'home_page_view',
}

interface ActivityPayload {
    activity_type: ActivityTrackerType;
    activityable_id?: number;
    duration_seconds: number;
    metadata: any[];
}

// Функция для отправки данных на бэкенд
const sendActivityData = async (payload: ActivityPayload): Promise<void> => {
    await fetchData(URLS.ACTIVITY_TRACKER_USER, {
        method: METHODS.POST,
        body: JSON.stringify(payload),
    });
};

interface UseActivityTrackerProps {
    activityType: ActivityTrackerType;
    activityableId?: number;
    metadata?: any[];
    autostart?: boolean;
}

// Константа для времени бездействия в миллисекундах (5 минут)
const INACTIVITY_TIMEOUT_MS = 5 * 60 * 1000;

export const useActivityTracker = ({
    activityType,
    activityableId,
    metadata = [],
    autostart = true,
}: UseActivityTrackerProps) => {
    const startTimeRef = useRef<number | null>(autostart ? Date.now() : null);
    const lastActivityTimeRef = useRef<number>(Date.now());
    const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isInactiveRef = useRef<boolean>(false);
    const [isTracking, setIsTracking] = useState<boolean>(autostart);

    // Функция для вычисления длительности активности в секундах
    const calculateDuration = (): number => {
        const currentTime = Date.now();
        // Если startTimeRef.current не инициализирован, вернуть 0
        if (startTimeRef.current === null) {
            return 0;
        }
        const durationMs = currentTime - startTimeRef.current;
        return Math.floor(durationMs / 1000); // конвертировать в секунды
    };

    // Функция для отправки данных активности
    const sendActivity = (isInactivityEvent: boolean = false) => {
        // Если отслеживание не началось или еще не установлено время начала, не отправляем
        if (startTimeRef.current === null) {
            return;
        }

        // Не отправляем повторное событие бездействия
        if (isInactivityEvent && isInactiveRef.current) {
            return;
        }

        if (isInactivityEvent) {
            isInactiveRef.current = true;
        }

        const payload: ActivityPayload = {
            activity_type: activityType,
            duration_seconds: calculateDuration(),
            metadata: [...metadata, isInactivityEvent ? { reason: 'inactivity_timeout' } : {}],
        };

        // Добавляем activityable_id только если он определен (для курсов)
        if (activityableId !== undefined) {
            payload.activityable_id = activityableId;
        }

        sendActivityData(payload);

        // Если это отправка из-за бездействия, сбрасываем таймер
        if (isInactivityEvent) {
            startTimeRef.current = Date.now();
        }
    };

    // Функция для обновления времени последней активности
    const updateLastActivityTime = () => {
        lastActivityTimeRef.current = Date.now();

        // Сбрасываем флаг бездействия при любой активности
        if (isInactiveRef.current) {
            isInactiveRef.current = false;
        }
    };

    // Функция для установки таймера бездействия
    const setupInactivityTimer = () => {
        // Очищаем предыдущий таймер, если он существует
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }

        // Устанавливаем новый таймер
        inactivityTimeoutRef.current = setTimeout(() => {
            // Проверяем, прошло ли 5 минут с момента последней активности
            const timeSinceLastActivity = Date.now() - lastActivityTimeRef.current;
            if (timeSinceLastActivity >= INACTIVITY_TIMEOUT_MS) {
                // Отправляем событие бездействия
                sendActivity(true);
            }

            // Перезапускаем таймер для следующей проверки
            setupInactivityTimer();
        }, INACTIVITY_TIMEOUT_MS);
    };

    // Функция для запуска отслеживания активности
    const startUserTrackerTimer = useCallback(() => {
        // Инициализируем время начала только при явном запуске таймера
        startTimeRef.current = Date.now();
    }, []);

    useEffect(() => {
        // Если отслеживание выключено, ничего не делаем
        if (!isTracking) {
            return;
        }

        // События для отслеживания активности пользователя
        const userActivityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

        // Обработчик событий активности пользователя
        const handleUserActivity = () => {
            updateLastActivityTime();
        };

        // Добавляем обработчик события beforeunload для отправки данных при закрытии окна
        const handleBeforeUnload = () => {
            sendActivity();
        };

        // Устанавливаем таймер для проверки бездействия
        setupInactivityTimer();

        // Добавляем обработчики событий активности
        userActivityEvents.forEach((event) => {
            window.addEventListener(event, handleUserActivity, { passive: true });
        });

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Очистка при размонтировании компонента или остановке отслеживания
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);

            // Удаляем обработчики событий активности
            userActivityEvents.forEach((event) => {
                window.removeEventListener(event, handleUserActivity);
            });

            // Очищаем таймер бездействия
            if (inactivityTimeoutRef.current) {
                clearTimeout(inactivityTimeoutRef.current);
            }

            // Отправляем данные при размонтировании компонента только если трекинг был запущен
            if (startTimeRef.current !== null) {
                sendActivity();
            }
        };
    }, [isTracking, activityType, activityableId, JSON.stringify(metadata)]);

    return {
        resetUserTrackerTimer: () => {
            startTimeRef.current = null;
            lastActivityTimeRef.current = Date.now();
            isInactiveRef.current = false;
        },
        sendUserActivity: () => sendActivity(false),
        startUserTrackerTimer,
    };
};
