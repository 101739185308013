import { currencyMap } from '@/entities/formatted/common';
import { ActionSubType } from '@/features/subscription/create-sub';

export const formattedCourseList = (arr: any[], user: any, actionSubType: ActionSubType, isWatching?: boolean) => {
    return arr.map((course) => {
        return {
            course: {
                id: course.id,
                imgSrc: (course.thumb || course.thumb_big) ?? '',
                title: course.title,
                slug: course.slug,
                authorNames: JSON.parse(course.author_info),
                progress: course.progress,
                currency: currencyMap[course.currency],

                price: course.course_card_price,
                priceDiscount: course.course_card_discount,

                isBought: course.is_purchased,
            },
            isGuest: !user && true,
            isContinueWatch: (course.is_watching || isWatching) ?? false,
            isSubscription: user ? user.is_subscribe : null,
            actionSubType: actionSubType,
        };
    });
};
